






























































import Vue, { PropOptions } from 'vue';
import { headroom } from 'vue-headroom';
import { mapGetters } from 'vuex';

export type TopBar = Blok & {
  notification: Blok[];
  logo: BlokFieldAsset;
  markedUrlUnderline: BlokFieldAsset;
  websiteLinks: BlokFieldLink[];
  webappLinks: BlokFieldLink[];
};

export default Vue.extend({
  components: { headroom },
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<TopBar>,
  },
  data() {
    return {
      mobileMenuOpen: false,
      isLandingPage: false,
      personalizationData: {'hide_sales': false},
    };
  },
  computed: {
    ...mapGetters(['isLight']),
    isMobile(): boolean {
      if (process.browser) {
        return window.matchMedia('(max-width: 768px)').matches;
      }
      return false;
    },
  },
  watch: {
    mobileMenuOpen(newState): void {
      document.body.style.overflow = newState ? 'hidden' : '';
    },
  },
  created(): void {
    if (
      this.$nuxt.$route.path === '/landing' ||
      this.$nuxt.$route.path.startsWith('/lp/')
    ) {
      this.isLandingPage = true;
    }
    this.fetchPersonalizationData();
  },
  methods: {
    async fetchPersonalizationData(): Promise<void> {
      const personalizationData = await fetch('https://api.animaapp.com/rpc/get_personalization_data', {
        method: 'GET'
      }
      ).then((response) => response.json());
      this.personalizationData = personalizationData;
    },
    shouldHideLink(link: { url: { cached_url: string } }): boolean {
      const target = link.url.cached_url;
      if (this.personalizationData['hide_sales']) {
        if (target.includes('typeform.com') ||
          target.includes('calendly.com')) {
          return true;
        }
      }
      return false;
    },
    gtmTrackNavigation({ label }: { label: string }): void {
      this.$gtm.push({
        event: 'top_bar_clicks',
        event_category: 'Top Bar',
        event_action: `${label} Link Clicked`,
      });
    },
    gtmTrackStartFree(link: { label: string }) {
      if (link?.label?.toLowerCase() !== 'start free') return;
      // if link is undefined - it's ok to fire event.
      this.$gtm.push({
        event: 'start_free_button_click',
        event_category: 'Button',
        event_action: 'Top Button Clicked',
      });
    },
  },
});
