import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=3b892594&scoped=true&"
import script from "./TopBar.vue?vue&type=script&lang=ts&"
export * from "./TopBar.vue?vue&type=script&lang=ts&"
import style0 from "./TopBar.vue?vue&type=style&index=0&id=3b892594&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b892594",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/circleci/app/components/storyblok/Header.vue').default})
